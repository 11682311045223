//import React, { useEffect, useContext, useMemo, Suspense } from 'react'

import React, { useEffect, useContext, useMemo } from 'react'
import { useLoaderData } from '@remix-run/react'
import { json } from '@remix-run/node'
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
} from '@remix-run/react'

import { withEmotionCache } from '@emotion/react'
import { ChakraProvider, cookieStorageManagerSSR, extendTheme, localStorageManager, useConst } from '@chakra-ui/react'
import { ServerStyleContext, ClientStyleContext } from './context'

import indexcss from './index.css'

import getCookieObject from './Helpers/Cookies/getCookieObject'

import config from './Config/config'
//import { loadStripe } from '@stripe/stripe-js'
//import { Elements } from '@stripe/react-stripe-js'

export const links = () => {
  return [{ rel: "stylesheet", href: indexcss }];
};

export const loader = async ({ request }) => {
  //return request.headers.get('cookie') ?? ''
  //const userData = await getAccountDetails() 
  //console.log(request.headers.get('cookie'))
  const cookieHeader = request.headers.get('Cookie') || ''
  const cookieObject = getCookieObject(cookieHeader)

  return json({
    isAuthenticated: cookieObject['sessionId'],
    cookieObject: cookieObject,
    cookies: cookieHeader
  })
}

const Document = withEmotionCache(
  ({ children }, emotionCache) => {
    const serverStyleData = useContext(ServerStyleContext);
    const clientStyleData = useContext(ClientStyleContext);

    // Only executed on client
    useEffect(() => {
      // re-link sheet container
      emotionCache.sheet.container = document.head;
      // re-inject tags
      const tags = emotionCache.sheet.tags;
      emotionCache.sheet.flush();
      tags.forEach((tag) => {
        (emotionCache.sheet)._insertTag(tag);
      });
      // reset cache to reapply global styles
      clientStyleData?.reset();
    }, []);

    // 
    function getColorMode(cookies) {
      const match = cookies.match(new RegExp(`(^| )${CHAKRA_COOKIE_COLOR_KEY}=([^;]+)`));
      return match == null ? void 0 : match[2];
    }

    const DEFAULT_COLOR_MODE = "dark"
    const CHAKRA_COOKIE_COLOR_KEY = "chakra-ui-color-mode";

    let { cookies } = useLoaderData()

    if (typeof document !== "undefined") {
      cookies = document.cookie;
    }

    let colorMode = useMemo(() => {
      let color = getColorMode(cookies)

      if (!color && DEFAULT_COLOR_MODE) {
        cookies += ` ${CHAKRA_COOKIE_COLOR_KEY}=${DEFAULT_COLOR_MODE}`;
        color = DEFAULT_COLOR_MODE;
      }

      return color
    }, [cookies]);

    return (
      <html
        lang="en"
        {...colorMode
        && {
          "data-theme": colorMode,
          "style": { colorScheme: colorMode },
        }
        }
      >
        <head>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width,initial-scale=1" />
          <Meta />
          <Links />
          {serverStyleData?.map(({ key, ids, css }) => (
            <style
              key={key}
              data-emotion={`${key} ${ids.join(' ')}`}
              dangerouslySetInnerHTML={{ __html: css }}
            />
          ))}
          {/* Google Anlytics */}
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=G-HQXLCZT0DT`}
          />
          <script
            async
            id="gtag-init"
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'G-HQXLCZT0DT', {
                  page_path: window.location.pathname,
                });
              `,
            }}
          />
        </head>
        <body
          {...colorMode && {
            className: `chakra-ui-${colorMode}`
          }}
        >
          {children}
          <ScrollRestoration />
          <Scripts />
          <LiveReload />
          {/* <Outlet /> */}
        </body>
      </html>
    );
  }
)

const theme = extendTheme({
  fonts: {
    heading: `'Raleway', sans-serif`,
    body: `'Raleway', sans-serif`,
  },
  colors: {
    daddysteach: {
      50: "#f1f9f9",
      100: "#c7e6e6",
      200: "#95d0d0",
      300: "#56b4b4",
      400: "#30a3a3",
      500: "#0c8b8b",
      600: "#0a7575",
      700: "#085e5e",
      800: "#075050",
      900: "#053a3a"
    }
  },
  components: {
    Checkbox: { // can be Radio
      baseStyle: {
        label: {
          touchAction: 'none',
        },
      },
    },
  },
})

// import { AuthProvider } from './Providers/AuthProvider'

import AdblockWrapper from './Components/AdblockWrapper'

export default function App() {
  let { cookies } = useLoaderData()
  const cookieManager = useConst(cookieStorageManagerSSR(cookies))
  //const stripe = loadStripe(config.STRIPE_PUBLIC_KEY)
  return (
    <Document>
      {/* <Elements stripe={stripe}> */}
      <ChakraProvider
        theme={theme}
        colorModeManager={typeof cookies === 'string' ? cookieManager : localStorageManager}
      >
        <AdblockWrapper>
          <Outlet />
        </AdblockWrapper>
      </ChakraProvider>
      {/* </Elements> */}
    </Document>
  )
}

/*

export default function App() {
  let { cookies } = useLoaderData()
  const cookieManager = useConst(cookieStorageManagerSSR(cookies))
  return (
    <Suspense fallback={<p>Loading...</p>}>
      <Document>
          <ChakraProvider
            theme={theme}
            colorModeManager={typeof cookies === 'string' ? cookieManager : localStorageManager}
          >
            <AdblockWrapper>
              <Outlet />
            </AdblockWrapper>
          </ChakraProvider>
      </Document>
    </Suspense>
  )
}

*/