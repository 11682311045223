import {
  Flex,
  Container,
  Stack,
  useMediaQuery,
  Heading,
  Text,
  useColorModeValue
} from '@chakra-ui/react'
import { SiAdblock } from 'react-icons/si'

import PrimaryButton from '../Elements/PrimaryButton'
import CustomHighlight from '../Elements/CustomHighlight'

import theme from '../../Config/theme'

const AdblockDetectedScreen = () => {
  const isDesktop = useMediaQuery("(min-width: 768px)")
  const primaryBgColor = useColorModeValue('gray.100', 'gray.900')
  const secondaryBgColor = useColorModeValue('whiteAlpha.800', 'gray.800')
  const borderColor = useColorModeValue(theme.borderColor.borderColorLight, theme.borderColor.borderColorDark)
  const textColor = useColorModeValue('gray.600', 'gray.300')

  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bgColor={primaryBgColor}
    >
      <Container
        bgColor={secondaryBgColor}
        maxW={isDesktop[0] && 'sm'}
        h={!isDesktop[0] && '100vh'}
        borderRadius={'lg'}
        border={'1px solid'}
        borderColor={borderColor}
        boxShadow={'md'}
      >
        <Stack
          direction={'column'}
          align={'center'}
          py={10}
          spacing={5}
        >
          <SiAdblock size={'50px'} style={{ color: theme.default.defaultColor }} />

          <Heading fontSize={'2xl'} textAlign={'center'}>
            Adblock Detected
          </Heading>

          <Text fontSize={'lg'} textAlign={'center'}>
            We rely on advertising to keep our services running. Please disable your ad blocker or <a href="/pricing" style={{ textDecoration: `underline ${theme.default.defaultColor}`, textDecorationThickness: '2px' }}>subscribe</a> to our VIP plan to remove ads.
          </Text>

          <PrimaryButton
            label={'I will turn off AdBlock'}
            onClick={() => window.location.replace('/')}
          />

          <Text fontSize={'sm'} textAlign={'center'} color={textColor}>
            <CustomHighlight query={['contact@daddysteach.com']} color={textColor}>
              Not using Adblock? Contact us at contact@daddysteach.com
            </CustomHighlight>
          </Text>
        </Stack>
      </Container>
    </Flex>
  )
}

export default AdblockDetectedScreen