import React, { useState, useEffect, useContext } from 'react'
import { useDetectAdBlock } from 'adblock-detect-react'

import { getAccountDetails } from '../../Api/MyAccount'
import useIsAuthenticated from '../../CustomHooks/useIsAuthenticated'

import AdblockDetectedScreen from './AdblockDetectedScreen'

const AdblockWrapper = ({ children }) => {
  const isAuthenticated = useIsAuthenticated()

  const [isAdblockDetected, setIsAdblockDetected] = useState(false)
  const adblockDetected = useDetectAdBlock() 

  useEffect(() => {
    const fetchAccountDetails = async () => {
      const response = await getAccountDetails()

      if (adblockDetected) {

        //if (response.data.error) {}
        if (response.data.errorType === 'serverUnavailable') {
          setIsAdblockDetected(false)
          return
        }

        if (response?.data?.data?.planId > 2) {
          setIsAdblockDetected(false)
          return
        }

        setIsAdblockDetected(true)
      } else {
        setIsAdblockDetected(false)
      }

    }

    if (isAuthenticated) {
      fetchAccountDetails()
    } else {
      if (adblockDetected) {
        setIsAdblockDetected(true)
      }
    }

  }, [adblockDetected])

  if (isAdblockDetected) {
    return (
      <AdblockDetectedScreen />
    )
  }

  if (!isAdblockDetected) {
    return (
      <>
        {children}
      </>
    )
  }
}

export default AdblockWrapper